export const environment = {
    companyName: 'WamNumbers-Review',
    API_URL: 'https://review.wamnumbers.com/api/v1/',
    APP_LOGO      : 'assets/images/logo.png',
  	APP_LOGO_MINI : 'assets/images/logo-mini.png',
    APP_LOGO_COLOR      : 'assets/images/logo_color.png',
  	APP_LOGO_MINI_COLOR : 'assets/images/logo_color-mini.png',
    EMAIL_REGEX: /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
    PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,64})/,
    NAME_REGEX: /^[a-zA-Z0-9_ ]*$/,
    CAPTCHA_SITE_KEY: '6LcF8NIpAAAAADuuiulZtmIyEbC8zY3ZuDTy3Wg2',
    SUPPORT_EMAIL: 'supports@wamnumbers.com',
    NO_REPLY_EMAIL: 'no_reply@wamnumbers.com',
    STRIPE_PUBLIC_KEY: 'pk_test_51OzS2nP5UJRdBwidCKGghfRCtWV47rAjyGsbniCc677X1ELp0ORIYZAzXtR6gWypb0I3W9hmKQZLbGMiR7Izvwsn00sND7R8Bv'
};
